import { Link } from "@/components/catalyst/link"

export default function AlreadySubscribedWarning({
  managePath,
  onSubscribeAgain,
  onManage,
}: {
  managePath: string
  onSubscribeAgain: () => void
  onManage: () => void
}) {
  const isPlan = managePath.includes("/plans/")

  return (
    <div className="w-full px-6 py-8">
      {/* Content */}
      <div className="mb-8 text-center">
        <h2 className="mb-4 text-2xl font-semibold">
          {isPlan ? "Already Purchased" : "Already Subscribed"}
        </h2>
        <p className="text-gray-600">
          {isPlan
            ? "You’re already on this Installment Plan. Purchasing again will result in double billing. Would you still like to proceed?"
            : "You’re currently subscribed to this Service. Subscribing again will result in double billing. Would you still like to proceed?"}
        </p>
      </div>

      {/* Buttons */}
      <div className="flex flex-col gap-3">
        <Link
          href={managePath}
          onClick={onManage}
          className="w-full rounded-lg bg-brand-blue-alt px-4 py-3 text-center font-bold text-white transition-colors hover:bg-indigo-500 focus-visible:outline-indigo-600"
        >
          {isPlan ? "Manage Current Plan" : "Manage Subscription"}
        </Link>
        <button
          onClick={onSubscribeAgain}
          className="w-full rounded-lg bg-gray-100 px-4 py-3 text-center font-bold text-gray-700 transition-colors hover:bg-gray-200"
        >
          Purchase Again
        </button>
      </div>
    </div>
  )
}
