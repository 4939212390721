import { AnyEventObject, assign, createMachine } from "xstate"
import AppleIcon from "@/components/icons/svg/Apple"
import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { useMachine } from "@xstate/react"
import * as Functions from "@/firebase/clientApp"
import { UserContext } from "@/context/UserContext"
import { useEffectOnce, useInterval } from "usehooks-ts"
import * as rdd from "react-device-detect"
import { SohneBreit700 } from "@/styles/fonts"
import Bugsnag from "@bugsnag/js"
import { GSIClientScriptContext } from "@/components/GSIClientScript"
import { usePathname } from "next/navigation"
import { UnsupportedBrowserMessage } from "@/components/UnsupportedBrowserCheck"
import * as Styles from "@/components/Styles"
import { SignInAndPayHeader } from "@/components/AuthenticateSlideContent"
import { validateLoginEmailAction } from "@/app/actions"
import { useFirebase } from "@/firebase/FirebaseProvider"
import {
  onAuthStateChanged,
  signInAnonymously,
  signInWithCustomToken,
  updateEmail,
} from "@firebase/auth"

export const GoogleIcon = () => {
  return (
    <div className={"size-[22px]"}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48 48"
        className="LgbsSe-Bz112c"
      >
        <g>
          <path
            fill="#EA4335"
            d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
          ></path>
          <path
            fill="#4285F4"
            d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
          ></path>
          <path
            fill="#FBBC05"
            d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
          ></path>
          <path
            fill="#34A853"
            d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
          ></path>
          <path fill="none" d="M0 0h48v48H0z"></path>
        </g>
      </svg>
    </div>
  )
}

const wrapBugsnagNotifyError = (message: string, f: any) => {
  return (ctx: any, event: any) => {
    const err = new Error(event.data.message || message, { cause: event.data })
    console.error({ message: event.data.message || message, event, ctx })
    Bugsnag.notify(err)
    return f(ctx, event, err)
  }
}

const stateMachine = createMachine<any, AnyEventObject>({
  id: "authFlow",
  predictableActionArguments: true,
  initial: "waitingForUserToChoose",
  on: {
    AUTH_STATE_CHANGED: [
      {
        target: "signedIn",
        cond: { type: "userHasEmail" },
      },
      {
        target: "updatingUserEmail",
        cond: { type: "userEmailNeedsUpdating" },
      },
      {
        target: "signingOut",
        cond: { type: "userEmailMissing" },
      },
    ],
  },
  states: {
    waitingForUserToChoose: {
      tags: ["authenticationButtonsScreen"],
      on: {
        SUBMIT_EMAIL_INPUT: [
          {
            target: "fetchingRequestLoginForEmail",
            cond: { type: "isSubmittingSameEmailWithTypo" },
            actions: assign({
              email: (context: any, event: any) => {
                return event.email
              },
              fetchRequestLoginForEmailError: null,
              fetchEmailValidationError: null,
              fetchIdTokenForEmailCodeError: null,
              fetchRequestLoginForEmailResult: null,
              signInAnonymouslyResult: null,
              signInAnonymouslyError: null,
              fetchIdTokenForEmailCodeResult: null,
              fetchEmailValidationResult: null,
            }),
          },
          {
            target: "fetchingEmailValidation",
            actions: assign({
              email: (context: any, event: any) => {
                return event.email
              },
              fetchRequestLoginForEmailError: null,
              fetchEmailValidationError: null,
              fetchIdTokenForEmailCodeError: null,
              fetchRequestLoginForEmailResult: null,
              signInAnonymouslyResult: null,
              signInAnonymouslyError: null,
              fetchIdTokenForEmailCodeResult: null,
            }),
          },
        ],
      },
    },
    fetchingRequestLoginForEmail: {
      tags: ["authenticationButtonsScreen", "pending"],
      invoke: {
        src: "fetchRequestLoginForEmail",
        onDone: [
          {
            target: "waitingForUserToChooseCode",
            cond: { type: "isFetchRequestLoginForEmailSuccessful" },
            actions: assign({
              fetchRequestLoginForEmailResult: (context: any, event: any) => {
                return event.data
              },
            }),
          },
          {
            target: "signingInAnonymously",
            cond: {
              type: "isFetchRequestLoginForEmailUnsuccessfulBecauseUserNotFound",
            },
            actions: assign({
              fetchRequestLoginForEmailResult: (context: any, event: any) => {
                return event.data
              },
            }),
          },
        ],
        onError: {
          target: "waitingForUserToChoose",
          actions: assign({
            fetchRequestLoginForEmailError: wrapBugsnagNotifyError(
              "fetchRequestLoginForEmailError",
              (_: any, _event: any, error: Error) => {
                return { error }
              },
            ),
          }),
        },
      },
    },

    fetchingEmailValidation: {
      tags: ["authenticationButtonsScreen", "pending"],
      invoke: {
        src: "fetchEmailValidation",
        onDone: [
          {
            target: "waitingForUserToChoose",
            cond: { type: "isEmailSuspectedOfHavingTypo" },
            actions: assign({
              fetchEmailValidationResult: (context: any, event: any) => {
                return event.data
              },
            }),
          },
          {
            target: "waitingForUserToChoose",
            cond: { type: "isEmailInvalid" },
            actions: assign({
              fetchEmailValidationResult: (context: any, event: any) => {
                return event.data
              },
            }),
          },
          {
            target: "fetchingRequestLoginForEmail",
            actions: assign({
              fetchEmailValidationResult: (context: any, event: any) => {
                return event.data
              },
            }),
          },
        ],
        onError: {
          target: "waitingForUserToChoose",
          actions: assign({
            fetchEmailValidationError: wrapBugsnagNotifyError(
              "fetchEmailValidationError",
              (_: any, _event: any, error: Error) => {
                return { error }
              },
            ),
          }),
        },
      },
    },

    signingInAnonymously: {
      tags: ["authenticationButtonsScreen", "pending"],
      invoke: {
        src: "signInAnonymously",
        onError: {
          target: "waitingForUserToChoose",
          actions: assign({
            signInAnonymouslyError: wrapBugsnagNotifyError(
              "signInAnonymouslyError",
              (_: any, _evt: any, error: Error) => {
                return { error }
              },
            ),
          }),
        },
      },
    },
    updatingUserEmail: {
      tags: ["authenticationButtonsScreen", "pending"],
      invoke: {
        src: "updateUserEmail",
        onDone: { target: "signedIn" },
        onError: {
          target: "signingOut",
          actions: assign({
            signingOutError: wrapBugsnagNotifyError(
              "signingOutError",
              (_: any, _evt: any, error: Error) => {
                return { error }
              },
            ),
          }),
        },
      },
    },
    signedIn: {},
    signingOut: {
      invoke: {
        src: "signOutUser",
        onDone: { target: "waitingForUserToChoose" },
      },
    },
    signedInAnonymously: {},
    waitingForUserToChooseCode: {
      tags: ["emailCodeScreen"],
      on: {
        SUBMIT_EMAIL_CODE_INPUT: {
          target: "fetchingIdTokenForEmailCode",
          actions: assign({
            emailCode: (context, event: any) => {
              return event.emailCode
            },
          }),
        },
      },
    },
    fetchingIdTokenForEmailCode: {
      tags: ["emailCodeScreen"],
      invoke: {
        src: "fetchIdTokenForEmailCode",
        onDone: [
          {
            target: "signingInWithIdTokenFromEmailCode",
            actions: assign({
              fetchIdTokenForEmailCodeResult: (context: any, event: any) => {
                return event.data
              },
            }),
          },
        ],
        onError: {
          target: "waitingForUserToChooseCode",
          actions: assign({
            fetchIdTokenForEmailCodeError: wrapBugsnagNotifyError(
              "fetchIdTokenForEmailCodeError",
              (_: any, _event: any, error: Error) => {
                return { error }
              },
            ),
          }),
        },
      },
    },
    fetchIdTokenForEmailCodeSuccess: {
      tags: ["emailCodeScreen"],
      on: {
        "": [
          {
            target: "signingInWithIdTokenFromEmailCode",
            cond: (context: any, _) =>
              !!context?.fetchIdTokenForEmailCodeResult?.token,
          },
        ],
      },
    },
    signingInWithIdTokenFromEmailCode: {
      tags: ["emailCodeScreen"],
      invoke: {
        src: "signInWithIdTokenFromEmailCode",
        onDone: { target: "signedIn" },
        onError: {
          target: "waitingForUserToChooseCode",
          actions: assign({
            signInWithIdTokenFromEmailCodeError: wrapBugsnagNotifyError(
              "signInWithIdTokenFromEmailCodeError",
              (_: any, _event: any, error: Error) => {
                return { error }
              },
            ),
          }),
        },
      },
    },
    signInWithIdTokenFromEmailCodeSuccess: {},
    signInWithIdTokenFromEmailCodeFailure: {},
  },
})

function ErrorMessage({ message }: { message?: string }) {
  return (
    <p
      data-testid={"error-message"}
      className={"text-center text-sm text-red-400"}
    >
      {message || "Unknown Error"}
    </p>
  )
}

function isAndroidWebview() {
  const navigator = window.navigator
  const userAgent = navigator.userAgent
  const normalizedUserAgent = userAgent.toLowerCase()
  const isAndroid = normalizedUserAgent.includes("android")

  return isAndroid && normalizedUserAgent.includes("; wv)")
}

export default function AuthFlow(props: PropsWithChildren) {
  const { auth, functions } = useFirebase()

  const {
    renderButton: renderGoogleSignInButton,
    isLoaded: isGSIClientLibLoaded,
  } = useContext(GSIClientScriptContext)

  const [state, send] = useMachine(stateMachine, {
    context: {} as any,
    guards: {
      isFetchRequestLoginForEmailSuccessful: (context, event) => {
        const { success, nonce } = event.data
        return !!(success && nonce)
      },
      isFetchRequestLoginForEmailUnsuccessfulBecauseUserNotFound: (
        context,
        event,
      ) => {
        const { success, nonce, code } = event.data
        return !success && code === "NOT_FOUND"
      },
      isEmailSuspectedOfHavingTypo: (context, event) => {
        return typeof event.data?.validation?.suggestion === "string"
      },
      isSubmittingSameEmailWithTypo: (context, event) => {
        const resubmittingSameEmailAsBefore =
          context.fetchEmailValidationResult?.validation?.email === event.email
        const isEmailStillSuspectedOfHavingType =
          typeof context.fetchEmailValidationResult?.validation?.suggestion ===
          "string"
        return (
          resubmittingSameEmailAsBefore && isEmailStillSuspectedOfHavingType
        )
      },
      isEmailInvalid: (context, event) => {
        return !event.data?.validation?.isValid
      },
      userHasEmail: (context, event) => {
        return !!event.user.email
      },
      userEmailNeedsUpdating: (context, event) => {
        return context.email && !event.user.email
      },
      userEmailMissing: (context, event) => {
        return !context.email && !event.user.email
      },
    },
    services: {
      fetchRequestLoginForEmail: async (context: any, event) => {
        const result = await Functions.AuthRequestLoginEmail(functions, {
          email: context.email,
        })

        return result.data
      },
      fetchEmailValidation: async (context: any, event) => {
        const result = await validateLoginEmailAction({ email: context.email })

        return { validation: result.validation }
      },
      signInAnonymously: async (context: any, event) => {
        await signInAnonymously(auth)
      },
      updateUserEmail: async (context, event) => {
        await updateEmail(auth.currentUser!, context.email)
        await Functions.UsersSyncProfile(functions)
      },
      signOutUser: async (context, event) => {
        await auth.signOut()
      },
      signInWithIdTokenFromEmailCode: async (context: any, event) => {
        await signInWithCustomToken(
          auth,
          context.fetchIdTokenForEmailCodeResult.token,
        )

        return {}
      },
      fetchIdTokenForEmailCode: async (context: any, event) => {
        const result = await Functions.AuthLoginWithEmail(functions, {
          email: context.email,
          code: context.emailCode,
          nonce: context.fetchRequestLoginForEmailResult.nonce,
        })

        if (result.data.success) {
          return result.data
        } else {
          throw new Error(result.data.message)
        }
      },
    },
  })

  useEffect(() => {
    return onAuthStateChanged(auth, (user) => {
      if (user) {
        send({
          type: "AUTH_STATE_CHANGED",
          user: { email: user.email },
        })
      }
    })
  }, [auth, send])

  const [isUnsupportedBrowser, setIsUnsupportedBrowser] = useState(false)
  const userContext = useContext(UserContext)

  useEffectOnce(() => {
    const isMetaInAppBrowser = /FBAN|FBAV|INSTAGRAM/i.exec(
      window.navigator.userAgent,
    )
    const isAppleWebKit = /AppleWebKit/i.exec(window.navigator.userAgent)

    let isSneakyFacebookScriptLoaded = false

    const scripts = document.getElementsByTagName("script")
    for (let i = 0; i < scripts.length; i++) {
      try {
        const e = scripts[i]
        if (e.getAttribute("src")) {
          const url = new URL(e.getAttribute("src")!)
          if (url.host && url.host === "connect.facebook.net") {
            isSneakyFacebookScriptLoaded = true
          }
        }
      } catch (err) {
        // ignore
      }
    }

    setIsUnsupportedBrowser(
      !!(
        (isMetaInAppBrowser && isAppleWebKit) ||
        isAndroidWebview() ||
        isSneakyFacebookScriptLoaded
      ),
    )
  })

  const [isEmailValid, setIsEmailValid] = useState(false)
  const [isEmailCodeValid, setIsEmailCodeValid] = useState(false)

  const emailInputRef = useRef<HTMLInputElement>(null)
  const appleSignInButtonRef = useRef<HTMLButtonElement>(null)
  const googleSignInButtonContainerRef = useRef<HTMLDivElement>(null)

  const submitEmailCode = () => {
    const emailCodeInput = document.getElementById(
      "emailCode",
    ) as HTMLInputElement

    send({
      type: "SUBMIT_EMAIL_CODE_INPUT",
      emailCode: emailCodeInput.value,
    })
  }

  const emailCodeRef = useRef<HTMLInputElement>(null)

  const [isAppleClientLibLoaded, setIsAppleClientLibLoaded] = useState(false)

  useInterval(
    () => {
      // @ts-ignore
      const lib = window.AppleID
      if (lib) {
        setIsAppleClientLibLoaded(true)
      }
    },
    isAppleClientLibLoaded ? null : 100,
  )

  const { AppleID } = window as any

  useEffect(() => {
    if (emailCodeRef.current) {
      emailCodeRef.current.focus()
    }
  }, [])

  useEffect(() => {
    if (appleSignInButtonRef.current && isAppleClientLibLoaded) {
      AppleID.auth.renderButton()
    }
  }, [appleSignInButtonRef.current, isAppleClientLibLoaded])

  useEffect(() => {
    if (googleSignInButtonContainerRef.current && isGSIClientLibLoaded) {
      const containerWidth = document.getElementById(
        "signInButtonsContainer",
      )!.offsetWidth
      const googleSignInButtonHTMLElement =
        document.getElementById("googleSignInButton")!
      const renderButtonOptions = {
        theme: "outline",
        width: containerWidth,
        size: "large",
        logo_alignment: "center",
      }
      renderGoogleSignInButton(
        googleSignInButtonHTMLElement,
        renderButtonOptions,
      )
    }
  }, [googleSignInButtonContainerRef.current])

  const pathname = usePathname()
  const [isAndroidPaymentSheet, setIsAndroidPaymentSheet] = useState(false)
  const [isUpdatePrivateRelay, setIsUpdatePrivateRelay] = useState(false)

  useEffect(() => {
    // A hack. I will probably refactor/rewrite "sign-in options" logic if further changes are
    // needed
    const pathnameSegments = pathname.slice(1).split("/")
    setIsAndroidPaymentSheet(
      rdd.isAndroid &&
        pathnameSegments.length === 2 &&
        ["offerings", "providers"].includes(pathnameSegments[0]),
    )
    setIsUpdatePrivateRelay(
      pathnameSegments.length === 1 &&
        pathnameSegments[0] === "update_private_relay",
    )
  }, [pathname])

  const [isAppleAuthLoading, setIsAppleAuthLoading] = useState(false)

  const appleSignInButtonOnClick = () => {
    setIsAppleAuthLoading(true)
    setTimeout(() => setIsAppleAuthLoading(false), 5000)
    setTimeout(() => {
      AppleID.auth.signIn().catch((result: any) => {
        if (result.error !== "popup_closed_by_user") {
          Bugsnag.notify(new Error("Apple signIn error", { cause: result }))
        }
      })
    }, 50)
  }

  const isSignInWithAppleVisible =
    !userContext.loadingUser && !isUnsupportedBrowser && !isAndroidPaymentSheet
  const isSignInWithGoogleVisible =
    !userContext.loadingUser &&
    !isUnsupportedBrowser &&
    !isUpdatePrivateRelay &&
    isGSIClientLibLoaded
  const isSignInWithEmailVisible =
    !userContext.loadingUser && !isUpdatePrivateRelay
  const isBrowserNotSupportedVisible =
    !userContext.loadingUser &&
    !isSignInWithGoogleVisible &&
    !isSignInWithAppleVisible &&
    !isSignInWithEmailVisible

  const isSignedIn = state.value === "signedIn"

  if (isSignedIn) {
    return props.children
  }

  const emailTypoCorrectionSuggestion: string | undefined =
    state?.context?.fetchEmailValidationResult?.validation?.suggestion
  const isEmailInvalid =
    state?.context?.fetchEmailValidationResult?.validation?.isValid === false

  return (
    <>
      <SignInAndPayHeader />
      <div
        id={"signInButtonsContainer"}
        data-testid={"sign-in-sheet"}
        className={"flex flex-1 flex-col gap-3"}
      >
        {state.hasTag("authenticationButtonsScreen") && (
          <>
            {isBrowserNotSupportedVisible && <UnsupportedBrowserMessage />}
            {isSignInWithEmailVisible && (
              <>
                <div
                  className={`${Styles.brandHeadingTextClassName} mb-[10px]`}
                >
                  Enter email
                </div>
                <form
                  className={"flex w-full flex-col gap-[15px]"}
                  noValidate={true}
                  onSubmit={(e) => {
                    e.preventDefault()

                    if (isEmailValid) {
                      const emailInput = document.getElementById(
                        "email",
                      ) as HTMLInputElement
                      send({
                        type: "SUBMIT_EMAIL_INPUT",
                        email: emailInput.value,
                      })
                    }
                  }}
                >
                  <div className={"flex flex-col gap-1.5"}>
                    <input
                      ref={emailInputRef}
                      id={"email"}
                      name={"email"}
                      type={"email"}
                      autoComplete={"email"}
                      onChange={(e) => {
                        setIsEmailValid(
                          /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(
                            e.target.value.trim(),
                          ),
                        )
                      }}
                      data-testid={"email-input"}
                      disabled={state.hasTag("pending")}
                      className={`${
                        state.hasTag("pending")
                          ? "pointer-events-none opacity-50"
                          : ""
                      } w-full rounded-md border border-[#e3e3e3] p-[10px] focus:outline-none`}
                      placeholder={"email@example.com"}
                    />
                    {!!emailTypoCorrectionSuggestion && (
                      <button
                        onClick={() => {
                          if (emailInputRef.current) {
                            emailInputRef.current.value =
                              emailTypoCorrectionSuggestion
                          }
                        }}
                        type={"button"}
                        data-testid={"EMAIL_TYPO_SUGGESTION_TEXT"}
                        className={"mt-2 text-center text-sm text-indigo-600"}
                      >
                        Did you mean{" "}
                        <span className={"font-medium"}>
                          {emailTypoCorrectionSuggestion.replace(/.+(?=@)/, "")}
                        </span>
                        ?
                      </button>
                    )}
                    {isEmailInvalid && (
                      <p
                        data-testid={"EMAIL_VALIDATION_INVALID_TEXT"}
                        className={"text-center text-sm text-red-400"}
                      >
                        Not a valid email
                      </p>
                    )}
                  </div>
                  <button
                    data-testid={"email-button"}
                    formNoValidate={true}
                    disabled={state.hasTag("pending") || !isEmailValid}
                    className={`${
                      state.hasTag("pending")
                        ? "pointer-events-none opacity-50"
                        : ""
                    } ${
                      isEmailValid
                        ? "bg-indigo-600 text-white shadow-sm"
                        : "pointer-events-none bg-[#838383] text-white shadow-sm"
                    } h-[46px] rounded-md p-[10px] text-sm font-[600] focus:outline-none`}
                  >
                    {state.hasTag("pending") ? (
                      <div className={"relative w-full"}>
                        <span>Loading...</span>
                        <div role="status" className={"absolute right-0 top-0"}>
                          <svg
                            aria-hidden="true"
                            className="h-5 w-5 animate-spin fill-indigo-600 text-gray-200"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                        </div>
                      </div>
                    ) : (
                      "Continue"
                    )}
                  </button>
                  {state.context.fetchRequestLoginForEmailError && (
                    <ErrorMessage
                      message={
                        state.context.fetchRequestLoginForEmailError.error
                          .message
                      }
                    />
                  )}
                  {state.context.fetchEmailValidationError && (
                    <ErrorMessage
                      message={
                        state.context.fetchEmailValidationError.error.message
                      }
                    />
                  )}
                  {state.context.signInAnonymouslyError && (
                    <ErrorMessage
                      message={
                        state.context.signInAnonymouslyError.error.message
                      }
                    />
                  )}
                </form>
              </>
            )}

            {(isSignInWithGoogleVisible || isSignInWithAppleVisible) && (
              <div className={"flex flex-1 flex-col items-center gap-3 pt-11"}>
                <p
                  className={
                    "px-4 text-center text-[16px] font-medium text-[#979797]"
                  }
                >
                  Or add email from
                </p>

                <div
                  className={
                    "flex h-10 max-h-10 min-h-10 flex-row justify-center gap-x-2 overflow-hidden [&_*]:!outline-none"
                  }
                >
                  {isSignInWithGoogleVisible && (
                    <div
                      ref={googleSignInButtonContainerRef}
                      className={
                        "relative flex max-h-10 min-h-10 w-28 items-center justify-center overflow-hidden rounded-full ring-1 ring-inset ring-gray-300/90 transition-colors active:bg-sky-100 active:ring-sky-400 [&>svg]:text-black"
                      }
                    >
                      <GoogleIcon />
                      <div
                        className={
                          "absolute inset-0 bg-blue-200 opacity-[0.00001]"
                        }
                      >
                        <div id={"googleSignInButton"}></div>
                      </div>
                    </div>
                  )}
                  {isSignInWithAppleVisible && (
                    <button
                      type="button"
                      ref={appleSignInButtonRef}
                      onClick={() => {
                        appleSignInButtonOnClick()
                      }}
                      className={
                        "relative flex max-h-10 min-h-10 w-28 items-center justify-center overflow-hidden rounded-full ring-1 ring-inset ring-gray-300/90 transition-colors active:bg-sky-100 active:ring-sky-400 [&>svg]:text-black"
                      }
                    >
                      <AppleIcon size={28} />
                    </button>
                  )}
                </div>
              </div>
            )}
          </>
        )}

        {state.hasTag("emailCodeScreen") && (
          <>
            <form
              className={"flex w-full flex-col gap-[15px]"}
              id={"codeForm"}
              autoComplete={"off"}
              onSubmit={(e) => {
                e.preventDefault()
                if (isEmailCodeValid) {
                  submitEmailCode()
                }
              }}
            >
              <h1 className={SohneBreit700.className + " mb-[10px] text-lg"}>
                Enter code sent to your email
              </h1>
              <div
                className={
                  "flex flex-row justify-center rounded-md border-[1px] border-[#e3e3e3]"
                }
              >
                <input
                  data-testid={"email-code"}
                  ref={emailCodeRef}
                  inputMode="numeric"
                  pattern="[0-9]*"
                  autoComplete={"one-time-code"}
                  maxLength={4}
                  type="text"
                  id={"emailCode"}
                  className={`${
                    state.value === "signingInWithIdTokenFromEmailCode" ||
                    state.value === "fetchingIdTokenForEmailCode"
                      ? "pointer-events-none opacity-50"
                      : ""
                  } w-full overflow-visible border-none bg-transparent pl-[50px] pr-[10px] text-center text-[40px] tracking-[40px] shadow-none outline-none ring-0 placeholder:font-[300] placeholder:text-gray-400 focus:border-none focus:shadow-none focus:outline-none focus:ring-0`}
                  placeholder={"____"}
                  onChange={(e) => {
                    const value = e.target.value

                    const isValid = value.length >= 4

                    setIsEmailCodeValid(isValid)

                    if (value.length >= 4) {
                      e.target.blur()
                    }

                    if (isValid) {
                      submitEmailCode()
                    }
                  }}
                  disabled={
                    state.value === "signingInWithIdTokenFromEmailCode" ||
                    state.value === "fetchingIdTokenForEmailCode"
                  }
                />
              </div>
              <button
                disabled={
                  state.value === "signingInWithIdTokenFromEmailCode" ||
                  state.value === "fetchingIdTokenForEmailCode"
                }
                className={`${
                  state.value === "signingInWithIdTokenFromEmailCode" ||
                  state.value === "fetchingIdTokenForEmailCode"
                    ? "pointer-events-none opacity-50"
                    : ""
                } ${
                  isEmailCodeValid
                    ? "bg-indigo-600"
                    : "pointer-events-none bg-[#838383]"
                } h-[46px] rounded-md p-[10px] text-sm font-[600] text-white shadow-sm focus:outline-none`}
              >
                {state.value === "signingInWithIdTokenFromEmailCode" ||
                state.value === "fetchingIdTokenForEmailCode" ? (
                  <div className={"relative w-full"}>
                    <span>Loading...</span>
                    <div role="status" className={"absolute right-0 top-0"}>
                      <svg
                        aria-hidden="true"
                        className="h-5 w-5 animate-spin fill-indigo-600 text-gray-200"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                    </div>
                  </div>
                ) : (
                  "Sign in"
                )}
              </button>
              {state.context.fetchIdTokenForEmailCodeError && (
                <ErrorMessage
                  message={
                    state.context.fetchIdTokenForEmailCodeError.error.message
                  }
                />
              )}
              {state.context.signInWithIdTokenFromEmailCodeError && (
                <ErrorMessage
                  message={
                    state.context.signInWithIdTokenFromEmailCodeError.error
                      .message
                  }
                />
              )}
            </form>
          </>
        )}
      </div>
    </>
  )
}
