import React from "react"

export function SignInAndPayHeader() {
  return (
    <div className={"flex w-full flex-row gap-[5px] pb-[40px] pt-[3px]"}>
      <div className={"flex flex-1 flex-col items-center gap-[3px]"}>
        <div className={"w-full rounded-full bg-[#333333] p-[2px]"}></div>
        <div
          className={
            "flex w-full flex-row items-center justify-center gap-[5px] text-sm"
          }
        >
          Your Details
        </div>
      </div>
      <div
        className={
          "flex flex-1 flex-col items-center gap-[3px] text-sm opacity-30"
        }
      >
        <div className={"w-full rounded-full bg-[#333333] p-[2px]"}></div>
        <div>Pay</div>
      </div>
    </div>
  )
}
