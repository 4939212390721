import React, { useContext, useEffect } from "react"
import { Elements } from "@stripe/react-stripe-js"
import CheckoutForm from "@/components/CheckoutForm"
import LoadingSpinner from "@/components/LoadingSpinner"

import { UserContext } from "@/context/UserContext"
import { usePaymentSheetContext } from "@/context/PaymentSheetContext"
import { SlideContext } from "@/context/SlideContent"
import { StripeElementsOptions } from "@stripe/stripe-js/dist/stripe-js/elements-group"
import clog from "@/util/clog"

const stripePromise = React.cache(async () => {
  const loadStripe = (await import("@stripe/stripe-js")).loadStripe
  const stripeKey = process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY
  if (!stripeKey) {
    throw new Error("Stripe key not found")
  }

  return loadStripe(stripeKey)
})

export default function StripePaymentSlideContent() {
  const { requestPayment, clientSecret } = usePaymentSheetContext()
  const { context } = useContext(SlideContext)
  const { userV2: user, userRole } = useContext(UserContext)

  useEffect(() => {
    if (context.offering && user) {
      // TODO:KB 9/11/2024 shouldn't be passing in empty strings
      // temporary fix for type issues
      requestPayment({
        priceID: context.price?.id ?? "",
        override: context.override,
        offeringID: context.offering.offeringID,
        offeringType: offering?.type ?? "",
        termsID: context.price?.termsID ?? "",
      })
    }
  }, [context, user])

  const offering = context.offering

  const activeClientSecret = clientSecret
  clog({ activeClientSecret })

  const paymentDetails = activeClientSecret?.data.paymentDetails
  const subscriptionID = activeClientSecret?.data?.subscriptionID
  const purchaseID = activeClientSecret?.data?.purchaseID
  const isProduction =
    process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID === "newie-production"

  const activeStripeIntent =
    paymentDetails?.paymentIntent || paymentDetails?.setupIntent
  const activeStripeIntentKind =
    activeClientSecret &&
    ((paymentDetails?.paymentIntent && "payment") ||
      (paymentDetails?.setupIntent && "setup"))

  const activeClientSecretValue = activeStripeIntent?.secret

  // TODO: avoid recreating
  const options: StripeElementsOptions = {
    clientSecret: activeClientSecretValue,
    loader: "always",
    appearance: {
      theme: "stripe",
    },
  }

  return (
    <>
      {clientSecret ? (
        <Elements options={options} stripe={stripePromise()}>
          <CheckoutForm
            isFuture={context.isFuture}
            purchaseID={purchaseID}
            subscriptionID={subscriptionID}
            paymentDetails={paymentDetails}
            offering={offering}
            activeStripeIntentKind={activeStripeIntentKind}
          />
        </Elements>
      ) : (
        <div
          className={
            "my-[50px] flex w-full flex-col items-center justify-center"
          }
        >
          <LoadingSpinner />
          <br />
          Getting payment methods…
        </div>
      )}
      {subscriptionID &&
        (!isProduction || userRole === "ADMIN" || userRole === "STAFF") && (
          <div className={"flex flex-1 flex-col justify-end gap-1"}>
            <label>SubscriptionID:</label>
            <code>{subscriptionID}</code>
          </div>
        )}
    </>
  )
}
