import React from "react"
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { formatAmount } from "@/lib/formats"
import Bugsnag from "@bugsnag/js"

export default function CheckoutForm({
  paymentDetails,
  offering,
  activeStripeIntentKind,
  purchaseID,
  subscriptionID,
  isFuture,
}) {
  const stripe = useStripe()
  const elements = useElements()
  const { offeringID } = offering

  const [email, setEmail] = React.useState("")
  const [message, setMessage] = React.useState("")
  const [isStripeReady, setIsStripeReady] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)

  const stripeConfirm = async () => {
    const overrideParam = new URLSearchParams(window.location.search).get("o")

    const returnURL = new URL(window.location.origin)
    returnURL.pathname = "/payments/complete"
    returnURL.searchParams.append("offeringID", offeringID)
    if (overrideParam) {
      returnURL.searchParams.append("o", overrideParam)
    }
    if (subscriptionID) {
      returnURL.searchParams.append("subscriptionID", subscriptionID)
    }
    if (purchaseID) {
      returnURL.searchParams.append("purchaseID", purchaseID)
    }

    const confirmParams = {
      return_url: returnURL.toString(),
    }

    if (!stripe || !elements) {
      throw Error("Not expecting stripe to be missing here")
    }

    switch (activeStripeIntentKind) {
      case "payment":
        return await stripe.confirmPayment({ elements, confirmParams })
      case "setup":
        return await stripe.confirmSetup({ elements, confirmParams })
      default:
        throw new Error("unknown intent kind")
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    setIsLoading(true)

    const { error } = await stripeConfirm().catch((error) => {
      return { error }
    })

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message)
    } else {
      console.error(error)
      Bugsnag.notify(error)
      setMessage("An unexpected error occurred.")
    }

    setIsLoading(false)
  }

  const paymentElementOptions = {
    paymentMethodOrder: ["apple_pay", "card"],
    wallets: { googlePay: "never" },
  }

  const hasSetupFee = !!paymentDetails?.setupFee?.amount

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement
        id="payment-element"
        options={paymentElementOptions}
        onReady={() => setIsStripeReady(true)}
      ></PaymentElement>

      <button
        disabled={isLoading || !stripe || !elements}
        id="submit"
        data-testid={"stripe-checkout-button"}
        className={
          "z-10 my-[20px] flex w-full flex-row gap-x-[10px] rounded-md bg-indigo-600 px-[20px] py-[13px] text-[16px] text-sm font-semibold text-white shadow-sm transition-none"
        }
      >
        {isLoading ? (
          <div className={"relative w-full"}>
            <span>Processing...</span>
            <div role="status" className={"absolute right-0 top-0"}>
              <svg
                aria-hidden="true"
                className="h-5 w-5 animate-spin fill-indigo-600 text-gray-200"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            </div>
          </div>
        ) : (
          <span className={"w-full"}>
            {hasSetupFee
              ? `Pay ${formatAmount(paymentDetails?.setupFee?.amount, paymentDetails.currency)}`
              : isFuture
                ? "Set up"
                : `Pay ${formatAmount(paymentDetails.amount, paymentDetails.currency)}`}
          </span>
        )}
      </button>
      {message && <div className={"text-[#dc2727]"}>{message}</div>}
    </form>
  )
}
