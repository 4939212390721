"use client"

import { createContext, PropsWithChildren, useContext, useState } from "react"
import {
  createAgreement,
  purchase,
  subscriptionSubscribe,
  SubscriptionSubscribeResponse,
} from "@/firebase/clientApp"
import useSWRImmutable from "swr/immutable"
import { Functions } from "firebase/functions"
import { useFirebase } from "@/firebase/FirebaseProvider"

export type PaymentSheetContextValue = {
  clientSecret?: SubscriptionSubscribeResponse
  requestPayment: ({}: {
    offeringID: string
    priceID: string
    override?: string
    termsID?: string
    offeringType: string
  }) => void
}

const PaymentSheetContext = createContext<PaymentSheetContextValue | null>(null)

type PaymentRequestData = {
  offeringID: string
  priceID: string
  override?: string
  termsID?: string
  offeringType: string
}

async function requestPaymentFetcher(
  functions: Functions,
  { offeringID, priceID, override, termsID, offeringType }: PaymentRequestData,
) {
  let agreementID: string | undefined

  if (termsID) {
    // TODO: handle error
    const { data: createAgreementResponseData } = await createAgreement(
      functions,
      {
        offeringID,
        priceID,
      },
    )
    agreementID = createAgreementResponseData.agreementID
  }

  // TODO: handle error
  return await (
    offeringType === "SUBSCRIPTION" ? subscriptionSubscribe : purchase
  )(functions, {
    offeringID,
    priceID,
    override,
    ...(agreementID && { agreementID }),
  })
}

export default function PaymentSheetProvider({ children }: PropsWithChildren) {
  const { functions } = useFirebase()

  const [paymentRequestData, setPaymentRequestData] =
    useState<null | PaymentRequestData>(null)

  // TODO: handle error
  const {
    data: clientSecret,
    isLoading,
    error,
  } = useSWRImmutable(paymentRequestData, (data) =>
    requestPaymentFetcher(functions, data),
  )

  return (
    <PaymentSheetContext.Provider
      value={{
        clientSecret: !isLoading ? clientSecret : undefined,
        requestPayment: setPaymentRequestData,
      }}
    >
      {children}
    </PaymentSheetContext.Provider>
  )
}

export function usePaymentSheetContext(): PaymentSheetContextValue {
  const context = useContext(PaymentSheetContext)

  if (!context) {
    throw new Error(
      `usePaymentSheetContext should be used within the PaymentSheetProvider provider!`,
    )
  }

  return context
}
