"use client"

import { createContext, ReactNode, useState } from "react"
import { Offering } from "@/models/Offering"
import { Provider } from "@/models/Provider"
import { Price } from "@/models/Price"

export type SlideContextType = {
  open: boolean
  offering?: Offering
  provider?: Provider
  override?: string
  price?: Price
  isFuture?: boolean
}

export const SlideContext = createContext<{
  context: SlideContextType
  setContext: (context: SlideContextType) => void
}>({
  context: {
    open: false,
  },
  setContext: () => {},
})

export default function SlideContextComp({
  children,
}: {
  children: ReactNode
}) {
  const [context, setContext] = useState({ open: false })

  return (
    <SlideContext.Provider value={{ context, setContext }}>
      {children}
    </SlideContext.Provider>
  )
}
