import React from "react"
import { Dialog, DialogBody } from "@/components/catalyst/dialog"

export default function SlideFromBottom({
  children,
  isOpen,
  closeSlide,
}: {
  children: React.ReactNode
  isOpen: boolean
  closeSlide: () => void
}) {
  return (
    <Dialog
      onClose={closeSlide}
      open={isOpen}
      // when in dialog mode, should be wide enough to cover the background
      // content to reduce noise
      className={"min-h-[90dvh] sm:min-h-[60dvh] sm:min-w-[600px]"}
    >
      <div className={"flex items-center justify-end"}>
        <div
          className={"cursor-pointer text-[#4447F3]"}
          data-testid={"sheet-close-button"}
          onClick={closeSlide}
        >
          Close
        </div>
      </div>
      <DialogBody>{children}</DialogBody>
    </Dialog>
  )
}
