import {Timestamp} from '@firebase/firestore'
import {DateTime} from 'luxon'

type TimestampData = {
    _seconds: number,
    _nanoseconds: number
}

export type MaybeTimestamp = (Timestamp | TimestampData | string)

export function toTimestamp(maybeTimestamp: MaybeTimestamp) {

    if (typeof maybeTimestamp === 'string') {
        return Timestamp.fromDate(DateTime.fromISO(maybeTimestamp).toJSDate())
    } else if (maybeTimestamp instanceof Timestamp) {
        return maybeTimestamp
    } else {
        return new Timestamp(maybeTimestamp._seconds, maybeTimestamp._nanoseconds)
    }
}

