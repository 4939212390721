import { sortSubscriptions, Subscription } from "@/models/Subscription"
import {
  collection,
  Firestore,
  getDocs,
  query,
  where,
} from "@firebase/firestore"

// fetches and sorts subscriptions
// not real time, re-fetches every minute
export const getSubscriptions = async (
  firestore: Firestore,
  {
    userID,
    offeringID,
  }: {
    userID: string | null | undefined
    offeringID?: string
  },
): Promise<Subscription[]> => {
  if (!userID) return []

  // must provide either subscriptionID or offeringID

  let q = query(
    collection(firestore, "subscriptions"),
    where("userID", "==", userID),
  )

  if (offeringID) {
    q = query(q, where("offeringID", "==", offeringID))
  }

  const sub = await getDocs(q)

  return sortSubscriptions(sub.docs.map((doc) => doc.data()) as Subscription[])
}
