export default function AppleIcon({size}) {
    const defaultSize = 22;
    return (
        <svg width={size || defaultSize} height={size || defaultSize} className={'text-white'} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1996_22574)">
                <path d="M18.6758 7.60156C16.9297 7.60156 15.5 8.66797 14.5859 8.66797C13.6133 8.66797 12.3477 7.67188 10.8242 7.67188C7.92969 7.67188 5 10.0625 5 14.5625C5 17.375 6.07812 20.3398 7.42578 22.25C8.57422 23.8672 9.58203 25.1914 11.0352 25.1914C12.4648 25.1914 13.0977 24.2422 14.8789 24.2422C16.6836 24.2422 17.0938 25.168 18.6758 25.168C20.2461 25.168 21.2891 23.7266 22.2852 22.3086C23.3867 20.6797 23.8555 19.0977 23.8672 19.0156C23.7734 18.9922 20.7734 17.7617 20.7734 14.3281C20.7734 11.3516 23.1289 10.0156 23.2695 9.91016C21.7109 7.67188 19.332 7.60156 18.6758 7.60156ZM17.8555 5.70312C18.5703 4.83594 19.0742 3.65234 19.0742 2.45703C19.0742 2.29297 19.0625 2.12891 19.0391 2C17.8672 2.04688 16.4609 2.77344 15.6289 3.75781C14.9609 4.50781 14.3516 5.70312 14.3516 6.89844C14.3516 7.08594 14.3867 7.26172 14.3984 7.32031C14.4688 7.33203 14.5859 7.35547 14.7148 7.35547C15.7578 7.35547 17.0703 6.65234 17.8555 5.70312Z" fill="currentColor"/>
            </g>
            <defs>
                <clipPath id="clip0_1996_22574">
                    <rect width="18.8672" height="24.4805" fill="currentColor" transform="translate(5 2)"/>
                </clipPath>
            </defs>
        </svg>
    )
}
