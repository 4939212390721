import SlideFromBottom from "@/components/SlideFromBottom"
import { SignInAndPayHeader } from "@/components/AuthenticateSlideContent"
import React, { useContext, useEffect, useState } from "react"
import { UserContext } from "@/context/UserContext"
import StripePaymentSlideContent from "@/components/StripePaymentSlideContent"
import UserNameFieldContent from "@/components/UserNameFieldContent"
import LoadingSpinner from "@/components/LoadingSpinner"
import AuthFlow from "@/components/AuthFlow"
import { SlideContext } from "@/context/SlideContent"
import { useQuery } from "@tanstack/react-query"
import { getSubscriptions } from "@/models/subscriptions.client"
import AlreadySubscribedWarning from "@/components/offering/AlreadySubscribedWarning"
import { SubscriptionDisplayState } from "@/models/Subscription"
import { useFirebase } from "@/firebase/FirebaseProvider"

export default function CombinationSlide() {
  const { firestore } = useFirebase()
  const { context, setContext } = useContext(SlideContext)
  const { userV2: user, loadingUser: isLoadingUser } = useContext(UserContext)
  const userID = user?.uid

  const [subscribeAgain, setSubscribeAgain] = useState(false)

  const onClose = () => {
    setContext({ open: false })
    setSubscribeAgain(false)
  }

  // make sure subscribeAgain is false when the offering changes
  useEffect(() => {
    setSubscribeAgain(false)
  }, [context.offering?.offeringID])

  // NOTE:KB 18/11/2024 Checking if the user already subscribed
  // before showing the StripePaymentSlideContent
  //
  // NOT relying on loading all the subscriptions for the purchases page
  // since it won't scale and would potentially get expensive quickly
  // since for this to work, we would have to load all
  // the subscriptions for the user.
  const offeringID = context.offering?.offeringID

  const {
    data: subs,
    isLoading: isLoadingSubsQuery,
    error: subscriptionsError,
  } = useQuery({
    enabled: !!userID && !!offeringID,
    queryKey: ["subscriptions", userID, offeringID],
    queryFn: () => getSubscriptions(firestore, { userID, offeringID }),
    refetchInterval: 60000,
  })
  const isLoadingSubs = isLoadingUser || isLoadingSubsQuery

  const ignorableStates: SubscriptionDisplayState[] = [
    SubscriptionDisplayState.expired,
    SubscriptionDisplayState.expiring,
    SubscriptionDisplayState.failed,

    // If the user started the purchase process but cancelled we get
    // a pending subscription (i.e. an incomplete payment_intent).
    // In this case, since they never completed the purchase we can ignore it.
    // It will eventually get marked as failed.
    SubscriptionDisplayState.pending,
  ] as const

  const activeSubs = (subs ?? []).filter(
    (it) =>
      it.status &&
      !ignorableStates.includes(it.status as SubscriptionDisplayState),
  )

  // console.log("[CombinationSlide]", { subs, activeSubs })

  // do any of the subscription states no appear in the inactive states
  const anyActiveSub = activeSubs.length > 0
  // console.log("[CombinationSlide]", {
  //   offeringID: context.offering?.offeringID,
  //   anyActiveSub,
  //   activeSubs: activeSubs.length,
  // })

  const hasSubscribed = !isLoadingSubs && !subscriptionsError && anyActiveSub
  // console.log({ isLoadingSubs, isLoadingSubsQuery, hasSubscribed })

  const renderContent = () => {
    // 1. Make sure we have a user
    if (!user) {
      return <LoadingContent />
    }

    // 2. Make sure the user has a display name
    if (user && !user.displayName) {
      return <UserNameFieldContent />
    }

    // 3. If still loading subscriptions show loading spinner
    if (isLoadingSubs) {
      // keeps the loading spinner in the same place as the getting payment
      // methods message in StripePaymentSlideContent
      return (
        <div
          className={
            "my-[50px] flex w-full flex-col items-center justify-center"
          }
        >
          <LoadingSpinner />
          <br />{" "}
        </div>
      )
    }

    // 4. Check if already subscribe and if yes, confirm they want to continue
    if (hasSubscribed && !subscribeAgain) {
      // sorted by most recent when fetched
      const mostRecentSub = activeSubs[0]
      let managePath = "/purchases"
      if (mostRecentSub && mostRecentSub.price.type !== "INSTALLMENT") {
        managePath = `/purchases/subscriptions/${mostRecentSub.subscriptionID}`
      } else {
        managePath = `/purchases/plans/${mostRecentSub.subscriptionID}`
      }

      return (
        <AlreadySubscribedWarning
          managePath={managePath}
          onSubscribeAgain={() => setSubscribeAgain(true)}
          onManage={onClose}
        />
      )
    }

    // 5. Show the StripePaymentSlideContent if we have an offering
    if (context.offering) {
      return <StripePaymentSlideContent />
    }

    return null
  }

  return (
    <SlideFromBottom isOpen={context.open} closeSlide={onClose}>
      <AuthFlow>{renderContent()}</AuthFlow>
    </SlideFromBottom>
  )
}

function LoadingContent() {
  return (
    <>
      <SignInAndPayHeader />
      <div
        className={"mt-[50px] flex w-full flex-col items-center justify-center"}
      >
        <LoadingSpinner />
      </div>
    </>
  )
}
