'use client'
import {useEffect, useState} from "react";

export function UnsupportedBrowserMessage() {
    const [isCopied, setIsCopied] = useState(false);
    const [pageLink, setPageLink] = useState('');

    useEffect(() => {
        setPageLink(window.location.href);
    }, []);

    const copyPageLink = () => {

        navigator.clipboard.writeText(pageLink).then(() => {
            setIsCopied(true);
        }).catch(_err => {
            const input = document.getElementById('copyPageLinkInput');
            input.select();
            input.setSelectionRange(0, 99999);
            const didExecCommandSucceed = document.execCommand("copy");
            setIsCopied(didExecCommandSucceed);
        });


    }

    return (
        <div className={"flex flex-col justify-center items-center gap-y-4 h-[70%]"}>
            <h1 className={'text-xl font-[600] text-center'}>Browser not supported</h1>
            <p className={'text-center'}>Copy and paste this link into your internet app to continue.</p>
            <div className={'flex flex-row w-full'}>
                <input
                    className={'h-[50px] text-sm w-full appearance-none border-2 border-[#4350de] rounded-l rounded-r-none py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:border-[#4350de]'}
                    id={'copyPageLinkInput'}
                    onClick={copyPageLink}
                    readOnly={true}
                    type={'text'}
                    value={pageLink}/>
                <button
                    onClick={copyPageLink}
                    className={'h-[50px] w-[70px] bg-[#4350de] appearance-none border-2 border-[#4350de] rounded-r py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:border-[#4350de] flex items-center justify-center'}>

                    {isCopied ?
                        (
                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M11.6406 24.6836C12.1445 24.6836 12.543 24.4609 12.8242 24.0273L23.8984 6.58984C24.1094 6.25 24.1914 5.99219 24.1914 5.72266C24.1914 5.07812 23.7695 4.65625 23.125 4.65625C22.6562 4.65625 22.3984 4.80859 22.1172 5.25391L11.5938 22.0234L6.13281 14.875C5.83984 14.4648 5.54688 14.3008 5.125 14.3008C4.45703 14.3008 4 14.7578 4 15.4023C4 15.6719 4.11719 15.9766 4.33984 16.2578L10.4219 24.0039C10.7734 24.4609 11.1367 24.6836 11.6406 24.6836Z"
                                    fill="white"/>
                            </svg>
                        )
                        :
                        (
                            <svg width="28" height="30" viewBox="0 0 28 30" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M8.6875 12.4922H19.0234C19.4219 12.4922 19.7617 12.1641 19.7617 11.7656C19.7617 11.3672 19.4219 11.0273 19.0234 11.0273H8.6875C8.28906 11.0273 7.94922 11.3672 7.94922 11.7656C7.94922 12.1641 8.28906 12.4922 8.6875 12.4922ZM8.6875 16.6523H19.0234C19.4336 16.6523 19.7617 16.3242 19.7617 15.9141C19.7617 15.5156 19.4336 15.1992 19.0234 15.1992H8.6875C8.27734 15.1992 7.94922 15.5156 7.94922 15.9141C7.94922 16.3242 8.27734 16.6523 8.6875 16.6523ZM8.6875 21.0352H13.8906C14.2891 21.0352 14.6172 20.707 14.6172 20.3086C14.6172 19.9102 14.3008 19.582 13.8906 19.582H8.6875C8.27734 19.582 7.94922 19.9102 7.94922 20.3086C7.94922 20.707 8.28906 21.0352 8.6875 21.0352ZM7.63281 28.3945H20.0781C22.5039 28.3945 23.7109 27.1758 23.7109 24.7148V6.94922C23.7109 4.54688 22.5508 3.26953 20.207 3.26953H19.9258C19.9375 3.36328 19.9375 3.46875 19.9375 3.5625V4.61719C19.9375 4.80469 19.9258 4.99219 19.8906 5.15625H20.1719C21.2734 5.15625 21.8242 5.87109 21.8242 6.97266V24.6914C21.8242 25.8633 21.2031 26.5078 19.9844 26.5078H7.72656C6.50781 26.5078 5.88672 25.8633 5.88672 24.6914V6.97266C5.88672 5.87109 6.4375 5.15625 7.53906 5.15625H7.82031C7.78516 4.99219 7.77344 4.80469 7.77344 4.61719V3.5625C7.77344 3.46875 7.77344 3.36328 7.78516 3.26953H7.50391C5.16016 3.26953 4 4.54688 4 6.94922V24.7148C4 27.1758 5.20703 28.3945 7.63281 28.3945ZM10.2344 5.95312H17.4766C18.1562 5.95312 18.5664 5.51953 18.5664 4.79297V3.5625C18.5664 2.83594 18.1562 2.40234 17.4766 2.40234H16.3984C16.3164 1.07812 15.2148 0 13.8555 0C12.4961 0 11.3945 1.07812 11.3125 2.40234H10.2344C9.55469 2.40234 9.14453 2.83594 9.14453 3.5625V4.79297C9.14453 5.51953 9.55469 5.95312 10.2344 5.95312ZM13.8555 3.51562C13.293 3.51562 12.8359 3.04688 12.8359 2.49609C12.8359 1.92188 13.293 1.46484 13.8555 1.46484C14.418 1.46484 14.875 1.92188 14.875 2.49609C14.875 3.04688 14.418 3.51562 13.8555 3.51562Z"
                                    fill="white"/>
                            </svg>
                        )
                    }

                </button>
            </div>
        </div>
    );
}
