import { DateTime } from "luxon"
import { MaybeTimestamp, toTimestamp } from "@/models/MaybeTimestamp"
import { Price } from "@/models/Price"
import fp from "lodash/fp.js"
import { PriceChangeDetails } from "@/models/PriceChangeDetails"
import { isToday, isYesterday } from "date-fns"

export enum SubscriptionDisplayState {
  overdue = "overdue",
  upcoming = "upcoming",
  expiring = "expiring",
  active = "active",
  completed = "completed",
  incomplete = "incomplete",
  pending = "pending",
  paused = "paused",
  expired = "expired",
  failed = "failed",
}

const displayStateSort = {
  [SubscriptionDisplayState.overdue]: 0,
  [SubscriptionDisplayState.upcoming]: 1,
  [SubscriptionDisplayState.expiring]: 2,
  [SubscriptionDisplayState.active]: 3,
  [SubscriptionDisplayState.completed]: 4,
  [SubscriptionDisplayState.incomplete]: 5,
  [SubscriptionDisplayState.pending]: 6,
  [SubscriptionDisplayState.paused]: 7,
  [SubscriptionDisplayState.expired]: 8,
}

export type Subscription = {
  userID: string
  offeringPreview: { name: string }
  offeringUserPreview: {
    profileImageURL: string
    name: string
    subtitle?: string
  }
  userPreview: { name: string; email: string }
  price: Price
  basePrice: Price
  invoiceStatus?: string
  agreementID?: string
  subscriptionID: string
  offeringID: string
  displayStates?: SubscriptionDisplayState[]
  priceChangeDetails?: PriceChangeDetails
  nextChargeAt?: MaybeTimestamp | null
  renewsAt?: MaybeTimestamp | null
  renewedAt?: MaybeTimestamp | null
  expiresAt?: MaybeTimestamp
  billingRetriesAt?: MaybeTimestamp
  startAt?: MaybeTimestamp
  unpauseAt?: MaybeTimestamp
  status?: string
  isVisible?: boolean
  scheduledActions?: {
    PAUSE?: {
      at?: MaybeTimestamp
      args?: {
        until?: MaybeTimestamp
      }
    }
    CANCEL?: {
      at?: MaybeTimestamp
    }
  }
}

export function hasDisplayState(
  subscription: Subscription,
  subscriptionDisplayState: SubscriptionDisplayState,
): boolean {
  const displayStates = subscription.displayStates || []
  return displayStates.includes(subscriptionDisplayState)
}

export function sortDisplayStates(displayStates: SubscriptionDisplayState[]) {
  return displayStates.sort((a, b) => displayStateSort[a] - displayStateSort[b])
}

export function formatPaymentStatusDescriptionTimestamp(
  maybeTimestamp: MaybeTimestamp,
): string {
  const dateTime = DateTime.fromSeconds(toTimestamp(maybeTimestamp).seconds)
  const date = dateTime.toJSDate()
  if (isToday(date)) {
    return "Today"
  }

  if (isYesterday(date)) {
    return "Yesterday"
  }

  return dateTime.toFormat("d LLL yyyy")
}

export function formatPaymentStatusDescriptionTimestampDayOfWeekFmt(
  maybeTimestamp: MaybeTimestamp,
): string {
  return DateTime.fromSeconds(toTimestamp(maybeTimestamp).seconds).toFormat(
    "EEE, d MMM",
  )
}

export function formatPaymentStatusDescriptionTimestampShort(
  maybeTimestamp: MaybeTimestamp,
): string {
  return DateTime.fromSeconds(toTimestamp(maybeTimestamp).seconds).toFormat(
    "d LLL",
  )
}

export function nextStatusChange(subscription: Subscription): number {
  const t =
    subscription.renewsAt ||
    subscription.expiresAt ||
    subscription.nextChargeAt ||
    subscription.unpauseAt ||
    subscription.billingRetriesAt
  return ((t && toTimestamp(t).seconds) || Date.now()) / 1000
}

export function sortSubscriptions(subscriptions: Subscription[]) {
  const secondsNow = Date.now() / 1000
  const cloned = [...subscriptions]
  return cloned.sort((a, b) => {
    return (
      Math.abs(nextStatusChange(a) - secondsNow) -
      Math.abs(nextStatusChange(b) - secondsNow)
    )
  })
}

export function displayStatesLookup(displayStates: string[] = []) {
  return Object.fromEntries(displayStates.map((s) => [s, true]))
}

export function paymentStatusDescriptionText(
  subscription: Subscription,
): string | null {
  const maybeScheduledAction =
    subscription.scheduledActions?.PAUSE?.at ||
    subscription.scheduledActions?.CANCEL?.at

  const displayStates = subscription.displayStates || []

  const {
    overdue,
    upcoming,
    expiring,
    active,
    completed,
    incomplete,
    pending,
    paused,
    expired,
  } = displayStatesLookup(displayStates)
  const { nextChargeAt, renewsAt, expiresAt, startAt } = subscription

  const formatTimestamp = fp.flow(
    toTimestamp,
    formatPaymentStatusDescriptionTimestampDayOfWeekFmt,
  )

  const installment = subscription.price.type === "INSTALLMENT"

  if (upcoming && nextChargeAt) {
    return `Starts ${formatTimestamp(nextChargeAt)}`
  }

  if (
    active &&
    renewsAt &&
    !(maybeScheduledAction && maybeScheduledAction <= renewsAt)
  ) {
    return `Next payment ${formatTimestamp(renewsAt)}`
  }

  if (expiring && installment) {
    return `No further payments`
  }

  if (expiring && expiresAt) {
    return `No further payments\nPaid until ${formatTimestamp(expiresAt)}`
  }

  if (expiring && nextChargeAt) {
    return `No further payments\nPaid until ${formatTimestamp(nextChargeAt)}`
  }

  if (paused && nextChargeAt) {
    return `Payment resumes ${formatTimestamp(nextChargeAt)}`
  }

  if (expired && expiresAt) {
    return `Expired ${formatTimestamp(expiresAt)}`
  }

  if (completed) {
    return `Installment plan complete`
  }

  if (incomplete) {
    return `Installment plan incomplete`
  }

  if (
    overdue &&
    renewsAt &&
    !(maybeScheduledAction && maybeScheduledAction <= renewsAt)
  ) {
    return `Next renewal ${formatTimestamp(renewsAt)}`
  }

  if (overdue) {
    return `No further payments`
  }

  if (pending && nextChargeAt) {
    return `Auto-retry ${formatTimestamp(nextChargeAt)}`
  }

  if (pending && startAt) {
    return `Starts ${formatTimestamp(startAt)}`
  }

  return null
}

export function allDisplayStatesDistinctSorted(
  subscriptions: Subscription[],
): SubscriptionDisplayState[] {
  const allDisplayStates = subscriptions.flatMap(
    (subscription: Subscription) => subscription.displayStates || [],
  )
  const allDisplayStatesSet = new Set<SubscriptionDisplayState>(
    allDisplayStates,
  )
  const allDisplayStatesDistinct: SubscriptionDisplayState[] = []
  allDisplayStatesSet.forEach((subscriptionDisplayState) =>
    allDisplayStatesDistinct.push(subscriptionDisplayState),
  )
  return sortDisplayStates(allDisplayStatesDistinct)
}
