import {DateTime} from "luxon";
import {toTimestamp} from '../models/MaybeTimestamp'

const factors = {
    'AUD': 100.0,
    'USD': 100.0,
    'CAD': 100.0,
    'NZD': 100.0,
    'EUR': 100.0,
    'GBP': 100.0,
}

const prefixes = {
    'AUD': '$',
    'USD': '$',
    'CAD': '$',
    'NZD': '$',
    'EUR': '€',
    'GBP': '£'
}


export function formatAmount(unitAmount, currency) {
    const factor = factors[currency];

    if (!factor) return;

    const prefix = prefixes[currency];
    const suffix = (prefix == "$" ? " " + currency : "");

    return `${prefix}${(unitAmount / factor).toFixed(2)}${suffix}`;
}

export function formatAmount2({unitAmount, currency}) {
    return formatAmount(unitAmount, currency)
}

export function formatAmountBeta(unitAmount, currency) {

    const defaultLocale = new Intl.NumberFormat().resolvedOptions().locale || 'en-US';

    const currencyFormatter = new Intl.NumberFormat(defaultLocale, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    })

    return currencyFormatter.format(unitAmount / 100);
}


//TODO: Clean up and unit test
export function formatCohortDate(pricing) {

    const cohortDateTime = DateTime.fromSeconds(toTimestamp(pricing.cohortDate).seconds);

    return cohortDateTime.toLocaleString( {
        weekday: 'short',
        day: 'numeric',
        month: 'short'
    });
}


//TODO: Clean up and unit test
export function formatStartAnchor(pricing) {
    if (!pricing.startAnchor) {
        return null
    }

    const today = DateTime.now().startOf('day').set({hour: 9}).setZone(pricing.startAnchor.timeZone.identifier);

    const currentDay = today.weekday
    const distance = (pricing.startAnchor.offset + 7 - currentDay) % 7;
    const todayAdjusted = today.plus({days: distance})

    return todayAdjusted.toLocaleString({
        weekday: 'short',
        day: 'numeric',
        month: 'short'
    });
}

//TODO: Clean up and unit test
export function formatCohortBillingStart(pricing) {

    if (!pricing.cohortDate) {
        return null
    }

    const cohortDateTime = DateTime.fromSeconds(toTimestamp(pricing.cohortDate).seconds);

    return cohortDateTime.toLocaleString({
        weekday: 'short',
        day: 'numeric',
        month: 'short'
    });

}

//TODO: Unit test
export function formatPeriod(pricing) {

    if (pricing.period == null) {
        return ""
    }

    const unit = pricing.period.unit;
    const duration = pricing.period.duration;
    const iterations = pricing.iterations;

    let iterationUnit = "";

    if (iterations) {
        iterationUnit = `for ${iterations} payments`
    }
    if (duration > 1) {
        return `/ ${duration} ${unit}s ${iterationUnit}`;
    } else {
        return `/ ${unit} ${iterationUnit}`;
    }
}
